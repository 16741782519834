import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import gsap from 'gsap/gsap-core'
import loadable from '@loadable/component'
import { StaticImage } from 'gatsby-plugin-image'

import media from '@styles/media'
import colors from '@styles/colors'
import text from '@styles/text'

import useMedia from '@hooks/useMedia'

const TextAnimation = loadable(() => import('@components/TextAnimation'))

const StandFor: React.FC = () => {
  const wrapperRef = useRef(null)
  const img1Ref = useRef(null)
  const img2Ref = useRef(null)

  const [triggerTitle, setTriggerTitle] = useState(false)
  const [triggerText, setTriggerText] = useState(false)

  const tlStart = useMedia("top-=25% top", "top-=25% top", "top-=80% top", "top-=55% top")

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: tlStart
      }
    })
    tl.to(img1Ref.current, {
      duration: 0.8,
      height: '100%',
      ease: "circ.inOut"
    }, 0)
    tl.to(img2Ref.current, {
      duration: 0.8,
      height: '100%',
      ease: "circ.inOut"
    }, 0.4)
    tl.call(setTriggerTitle, [true], 0)
    tl.call(setTriggerText, [true], 0.2)

    const imgZoom = gsap.timeline({
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: "top-=25% top",
        scrub: true
      }
    })
    imgZoom.to(img1Ref.current, {
      scale: '1.2'
    }, 0)
    imgZoom.to(img2Ref.current, {
      scale: '1.2'
    }, 0)

    return () => {
      tl.kill()
    }
    
  }, [wrapperRef, img2Ref, img1Ref, setTriggerTitle, setTriggerText, tlStart])

  return (
    <Wrapper ref={wrapperRef}>
      <Content>
        <Title>
          <TextAnimation
            textArray={[
              () => "What we",
              () => "stand for"
            ]}
            className="about-standfor-title"
            height={useMedia("4.861vw", "4.861vw", "7.186vw", "16vw")}
            trigger={triggerTitle}
          />
        </Title>
        <Text>
          <TextAnimation
            textArray={[
              () => "We believe valuable energy operations shouldn't come at the expense of an irreplaceable planet.  Our team is motivated to find solutions to advance oil and gas practices to be safer, more efficient, and cleaner in order to better protect the environment.",
            ]}
            className="about-standfor-text"
            height={useMedia("10.417vw", "10.417vw", "17.605vw", "42.933vw")}
            trigger={triggerText}
          />
        </Text>
      </Content>
      <Img1Wrapper>
        <Img ref={img1Ref}>
          <StaticImage
            src={'../../images/jpg/standFor1.jpg'} 
            alt="factory 1"
            placeholder="blurred"
            objectFit="cover"
            objectPosition="center left"
            style={{width: '100%', height: '100%'}}
          />
        </Img>
      </Img1Wrapper>
      <Img2Wrapper>
        <Img ref={img2Ref}>
          <StaticImage
            src={'../../images/jpg/standFor2.jpg'} 
            alt="factory 2"
            placeholder="blurred"
            objectFit="cover"
            objectPosition="center left"
            style={{width: '100%', height: '100%'}}
          />
        </Img>
      </Img2Wrapper>
    </Wrapper>
  )
}

export default StandFor

const Wrapper = styled.section`
  background-color: ${colors.culturedWhite40};
  position: relative;

  ${media.fullWidth} {
    padding-top: 17.639vw;
    padding-right: 11.319vw;
    padding-left: 62.986vw;
    padding-bottom: 24.583vw;
  }

  ${media.desktop} {
    padding-top: 17.639vw;
    padding-right: 11.319vw;
    padding-left: 62.986vw;
    padding-bottom: 24.583vw;
  }

  ${media.tablet} {
    padding-top: 15.569vw;
    padding-right: 8.862vw;
    padding-left: 58.443vw;
    padding-bottom: 19.401vw;
  }

  ${media.mobile} {
    padding-top: 160.267vw;
    padding-left: 6.667vw;
    padding-bottom: 43.467vw;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${media.fullWidth} {
    width: 25.694vw;
  }

  ${media.desktop} {
    width: 25.694vw;
  }

  ${media.tablet} {
    width: 32.695vw;
  }

  ${media.mobile} {
    width: 72.800vw;
  }
`

const Title = styled.h2`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${colors.black};

  ${media.fullWidth} {
    ${text.desktopLHeading}
    margin-bottom: 2.083vw;
  }

  ${media.desktop} {
    ${text.desktopLHeading}
    margin-bottom: 2.083vw;
  }

  ${media.tablet} {
    ${text.tabletMHeading}
    margin-bottom: 2.395vw;
  }

  ${media.mobile} {
    ${text.mobileMHeading}
    margin-bottom: 5.333vw;
  }
`

const Text = styled.span`
  color: ${colors.black};
  display: flex;

  ${media.fullWidth} {
    ${text.desktopBodyCopy1}
    margin-bottom: 4.167vw;
  }

  ${media.desktop} {
    ${text.desktopBodyCopy1}
    margin-bottom: 4.167vw;
  }

  ${media.tablet} {
    ${text.tabletSmallBody}
    margin-bottom: 3.593vw;
  }

  ${media.mobile} {
    ${text.mobileSmallBody}
  }
`

const Img1Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  left: 0vw;

  ${media.fullWidth} {
    width: 51.806vw;
    height: 29.375vw;
    top: 11.458vw;
  }

  ${media.desktop} {
    width: 51.806vw;
    height: 29.375vw;
    top: 11.458vw;
  }

  ${media.tablet} {
    width: 41.557vw;
    height: 32.814vw;
    top: 8.743vw;
  }

  ${media.mobile} {
    width: 92.533vw;
    height: 73.067vw;
    top: 19.467vw;
  }
`

const Img2Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;

  ${media.fullWidth} {
    width: 30.347vw;
    height: 29.375vw;
    left: 26.875vw;
    top: 31.597vw;
  }

  ${media.desktop} {
    width: 30.347vw;
    height: 29.375vw;
    left: 26.875vw;
    top: 31.597vw;
  }

  ${media.tablet} {
    width: 35.689vw;
    height: 28.024vw;
    left: 13.413vw;
    top: 36.527vw;
  }

  ${media.mobile} {
    width: 71.200vw;
    height: 62.400vw;
    left: 29.600vw;
    top: 81.867vw;
  }
`

const Img = styled.div`
  width: 100%;
  height: 0%;
`